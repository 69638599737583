import React from "react";
import { useTranslation } from 'react-i18next';
import LogoSlider from "../../DefaultPageComponents/LogoSlider/LogoSlider";

import './clientele.css';

const Awards = () => {

    const { t } = useTranslation();

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    return (
        <>
            <section id="clientele_section">
                <h1 id="clientele_hdng">
                    {t('defaultPage_clientele.main_heading')}
                </h1>

                <LogoSlider />

                {/* {
                    checkResponsive()
                        ?
                        (
                            <div id="awards_div">

                                <MDBCard className="awards_card">
                                    <MDBRipple id="awards_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                        <div id="awards_card_img">
                                            <RotatingImages
                                                images={[
                                                    nicLogo,
                                                    founderLogo,
                                                    inseadLogo,
                                                    pashaLogo]}
                                                interval={3000} />
                                        </div>
                                    </MDBRipple>
                                </MDBCard>

                            </div>
                        )
                        :
                        (<div id="awards_div">

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={nicLogo} fluid alt='...' />
                            </MDBCard >

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={founderLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={inseadLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={pashaLogo} fluid alt='...' />
                            </MDBCard>


                        </div >

                        )
                } */}



            </section >
        </>
    );
};

export default Awards;