import React from "react";
import "./whatsapp_btn.css"; // Import the CSS for styling

const WhatsAppButton = () => {
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/+923232675312', '_blank');
    };

    return (
        <div className="whatsapp-btn" onClick={handleWhatsAppClick}>
            <i className="fab fa-whatsapp"></i>
        </div>
    );
};

export default WhatsAppButton;
