import React from 'react';
import Marquee from 'react-marquee-slider';
import { v4 as uuidv4 } from 'uuid';

import arifHabib from './arif habib.webp';
import falaknaz from './falaknaz.webp';
import stza from './stza.webp';
import star from './star.webp';
import aman from './aman builders.webp';
import abad from './abad.webp';
import sbp from './sbp.webp';
import ruda from './ruda.webp';
import knight from './knight frank.webp';
import gda from './gda.webp';
import lums from './lums.webp';
import nicKhi from './nic.webp';
import nicHyd from './nic hyd.webp';
import nicIsb from './nic isb.webp';
import dhaMultan from './dha multan.webp';
import dhaBhlpr from './dha bahawalpur.webp';
import defclarea from './defclarea.webp';
import akc from './akc.webp';
import aj from './aj.webp';
import akra from './akra.webp';
import apmf from './apmf.webp';
import asani from './asani.webp';
import fortify from './fortify.webp';
import biz from './biz.webp';
import cloud9 from './cloud 9.webp';
import country from './country.webp';
import urban from './urban colab.webp';
import creative3d from './creative 3d.webp';
import datastate from './datastate.webp';
import eproperty from './e property.webp';
import geida from './geida.webp';
import green from './green goodwill.webp';
import homy from './homy.webp';
import linkers from './linkers.webp';
import memonTv from './memon tv.webp';
import memonNews from './memon news.webp';
import ontime from './ontime.webp';
import pw from './pw.webp';
import ridaOman from './rida oman.webp';
import rosh from './rosh.webp';
import shirakat from './shirakat.webp';
import startup from './startuo.webp';
import talatEstate from './talat estate.webp';
import unison from './unison.webp';
import zulekha from './zulekha builders.webp';
import tpl from './tpl reit.webp';

const logos = [
    arifHabib, stza, falaknaz, star, sbp, aman, abad, ruda, knight, lums, gda, dhaBhlpr, dhaMultan, defclarea, akra, nicKhi, nicHyd, nicIsb, talatEstate, tpl, biz, memonNews, memonTv, apmf, akc, aj, asani, fortify, creative3d, ontime, rosh, country, cloud9, eproperty, geida, homy, green, linkers, urban, startup, shirakat, unison, zulekha, pw, ridaOman
];

const LogoSlider = () => {

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    return (
        <div style={{ width: '100%', height: 'auto', overflow: 'hidden', padding: '20px 0' }}>
            <Marquee velocity={checkResponsive ? '2' : '10'} minScale={3} resetAfterTries={200} scatterRandomly={false}>
                {logos.map((logo) => (
                    <div key={uuidv4()} style={{ padding: checkResponsive() ? '0px 20px' : '0px 40px' }}>
                        <img src={logo} alt="Client Logo" style={{ height: '120px', width: 'auto' }} />
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default LogoSlider;
