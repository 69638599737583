import React from "react";
import { useTranslation } from 'react-i18next';
import Carousel from "../../DefaultPageComponents/Carousel/Carousel";
import { Link } from "react-router-dom";

import './testimonial.css';

import { FaYoutube } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";

const Testimonials = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="testimonial_section">

                <h5 id="test_sbhdng">
                    {t('defaultPage_test.sub_heading')}
                    <Link id="link" target='_blank' to='https://www.youtube.com/@proptechconvention'>
                        <FaYoutube
                            style={{ fontSize: '30px', marginLeft: '10px', color: 'red', cursor: 'pointer' }} />
                    </Link>
                </h5>
                <h1 id="test_hdng">
                    {t('defaultPage_test.main_heading')}
                </h1>

                <div id="carosel_container">
                    <Carousel />
                </div>

                <button id="test_btn">
                    <Link id="link" target='_blank' to='https://www.youtube.com/@proptechconvention'>
                        {t('defaultPage_test.button')}
                        <MdKeyboardArrowRight />
                    </Link>
                </button>

            </section >
        </>
    );
};

export default Testimonials;