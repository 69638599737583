import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import ScrollTrigger from 'react-scroll-trigger';
import { Link } from "react-router-dom";

import './event.css';

import { FaCheckCircle } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import CountUp from 'react-countup';
import clubImg from './Untitled design (2).png';
import eventLogo from './al knowledge (6).png';
import clubLogo from './Untitled design.png';

const Event = () => {

    const { t } = useTranslation();

    const [counting, setCounting] = useState(false);

    return (
        <>
            <section id="proptech_parent_section">
                <section id="event_section">

                    <div id="event_bg">
                        <div id="event_txt_div">

                            <img id="event_logo" src={eventLogo} alt="" />

                            <h1 id="event_hdng">
                                {t('defaultPage_event.main_heading')}
                            </h1>
                            <hr id="event_hr" />
                            <p
                                id="event_txt" >
                                {t('defaultPage_event.subtext.text1')}
                                <br />
                                <b style={{ fontWeight: '600' }} >
                                    {t('defaultPage_event.subtext.bold')}
                                </b>
                                <br />
                                {t('defaultPage_event.subtext.text2')}
                            </p>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <span className="points">
                                    <FaCheckCircle style={{ color: '#00FF85', fontSize: '17px', marginRight: '7px' }} />
                                    {t('defaultPage_event.pointers.0')}
                                </span>
                                <span className="points">
                                    <FaCheckCircle style={{ color: '#00FF85', fontSize: '17px', marginRight: '7px' }} />
                                    {t('defaultPage_event.pointers.1')}
                                </span>
                                <span className="points">
                                    <FaCheckCircle style={{ color: '#00FF85', fontSize: '17px', marginRight: '7px' }} />
                                    {t('defaultPage_event.pointers.2')}
                                </span>
                                <span className="points">
                                    <FaCheckCircle style={{ color: '#00FF85', fontSize: '17px', marginRight: '7px' }} />
                                    {t('defaultPage_event.pointers.3')}
                                </span>
                            </div>

                            <button id="event_btn">
                                <Link id="link" target='_blank' to='https://proptech-convention.com'>
                                    {t('defaultPage_event.button')}
                                    <MdKeyboardArrowRight />
                                </Link>
                            </button>
                        </div>

                        {/* <ScrollTrigger onEnter={() => { setCounting(true); }}> */}
                        <div id="event_numbers_div">

                            <div id="numbers_parent_div">
                                <div className="numbers_box" style={{ borderWidth: '2px 1px 1px 2px' }}>
                                    <h3 className="number">
                                        <CountUp
                                            start={0}
                                            end={1000}
                                            duration={2}
                                        ></CountUp>
                                        +
                                    </h3>
                                    <p className="number_txt">
                                        {t('defaultPage_event.numbers.0')}
                                    </p>
                                </div>
                                <div className="numbers_box" style={{ borderWidth: '2px 2px 1px 1px' }}>
                                    <h3 className="number">
                                        <CountUp
                                            start={0}
                                            end={65}
                                            duration={2}
                                        ></CountUp>

                                    </h3>
                                    <p className="number_txt">
                                        {t('defaultPage_event.numbers.1')}
                                    </p>
                                </div>
                                <div className="numbers_box" style={{ borderWidth: '1px 1px 1px 2px' }}>
                                    <h3 className="number">
                                        <CountUp
                                            start={0}
                                            end={37}
                                            duration={2}
                                        ></CountUp>

                                    </h3>
                                    <p className="number_txt">
                                        {t('defaultPage_event.numbers.2')}
                                    </p>
                                </div>
                                <div className="numbers_box" style={{ borderWidth: '1px 2px 1px 1px' }}>
                                    <h3 className="number">
                                        <CountUp
                                            start={0}
                                            end={52}
                                            duration={2}
                                        ></CountUp>

                                    </h3>
                                    <p className="number_txt">
                                        {t('defaultPage_event.numbers.3')}
                                    </p>
                                </div>
                                <div className="numbers_box" style={{ borderWidth: '1px 2px 2px 2px' }}>
                                    <h3 className="number">
                                        <CountUp
                                            start={0}
                                            end={50}
                                            duration={2}
                                        ></CountUp>
                                        +
                                    </h3>
                                    <p className="number_txt">
                                        {t('defaultPage_event.numbers.4')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* </ScrollTrigger> */}
                    </div>

                </section>



                <section id="club_section">

                    <div id="club_info_div">

                        <img id="club_logo" src={clubLogo} alt="" />

                        <h5 id="club_sbhdng">
                            {t('defaultPage_club.sub_heading')}
                        </h5>
                        <h1 id="club_hdng">
                            {t('defaultPage_club.main_heading')}
                        </h1>

                        <p id="club_txt">
                            {t('defaultPage_club.text.text 1')}
                            <b style={{ color: '#CB9800' }}>{t('defaultPage_club.text.bold')}</b>
                            {t('defaultPage_club.text.text 2')}
                        </p>

                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <span className="points"> <FaCheckCircle style={{ color: '#083555', fontSize: '17px', marginRight: '7px' }} />
                                {t('defaultPage_club.pointers.0')}
                            </span>
                            <span className="points"> <FaCheckCircle style={{ color: '#083555', fontSize: '17px', marginRight: '7px' }} />
                                {t('defaultPage_club.pointers.1')}
                            </span>
                            <span className="points"> <FaCheckCircle style={{ color: '#083555', fontSize: '17px', marginRight: '7px' }} />
                                {t('defaultPage_club.pointers.2')}
                            </span>
                            <span className="points"> <FaCheckCircle style={{ color: '#083555', fontSize: '17px', marginRight: '7px' }} />
                                {t('defaultPage_club.pointers.3')}
                            </span>
                        </div>
                        <p id="club_cta_txt">
                            {t('defaultPage_club.highlight')}
                        </p>

                        <button id="club_btn">
                            <Link id="link" target='_blank' to='https://chat.whatsapp.com/KuZaeX9pGvVDG2Ux2ivzlp'>
                                {t('defaultPage_club.button')}
                                <MdKeyboardArrowRight />
                            </Link>
                        </button>
                    </div>

                    <div id="club_img_div">

                        <img id="club_img" src={clubImg} alt="" />
                    </div>

                </section >
            </section >
        </>
    );
};

export default Event;