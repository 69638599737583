import React from "react";
import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";
import "./footer.css";

import logo from './landtrack logo (1).png';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";


const Footer = () => {
    const { t } = useTranslation();

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    const handleLocate = () => {
        window.open(`https://www.google.com/maps/dir/24.8676352,67.059712/landtrack/@24.8735626,67.0555388,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3eb33f149f43cb7d:0x482adfb2d75ea580!2m2!1d67.0688732!2d24.8777252?entry=ttu`, '_blank');
    };

    return (
        <>
            <section id="footer_section">

                <div id="footer_Info_div">
                    <img id="footer_logo" src={logo} alt="" />

                    <p id="footer_info_txt">
                        {t('defaultPage_footer.text')}
                    </p>
                </div>

                <div id="footer_contact_div">
                    <h5 id="footer_links_hdng">
                        {t('defaultPage_footer.contact.heading')}
                    </h5>
                    <div id="footer_links_div">
                        <div style={{ width: '50%', flexWrap: 'wrap' }}>
                            <p
                                onClick={handleCall}
                                className="footer_links_txt" id="phone"><FaPhoneAlt /> +92 323 2675312</p>
                            <p
                                onClick={handleMail}
                                className="footer_links_txt" id="mail"><IoMdMail /> info@landtrack.pk</p>
                        </div>

                        <p
                            onClick={handleLocate}
                            className="footer_links_txt" id="address" style={{ width: "70%" }}><FaLocationDot /> SA-48, 3rd Floor, Shahnaz Arcade, Shaheed-E-Millat Rd, Karachi</p>
                    </div>

                    <h5 id="social_hdng">
                        {t('defaultPage_footer.social.heading')}
                    </h5>
                    <div id="social_icns_div">
                        <Link id="link" target='_blank' to='https://www.facebook.com/landtrack.pk/'>
                            <FaFacebook
                                style={{ color: 'whitesmoke', fontSize: '20px', cursor: 'pointer' }} />
                        </Link>

                        <Link id="link" target='_blank' to='https://www.instagram.com/landtrackpk/'>
                            <RiInstagramFill
                                style={{ color: 'whitesmoke', fontSize: '21px', cursor: 'pointer' }} />
                        </Link>

                        <Link id="link" target='_blank' to='https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true'>
                            <FaLinkedin
                                style={{ color: 'whitesmoke', fontSize: '20px', cursor: 'pointer' }} />
                        </Link>

                        <Link id="link" target='_blank' to='https://www.youtube.com/@landtrack'>
                            <FaYoutube
                                style={{ color: 'whitesmoke', fontSize: '20px', cursor: 'pointer' }} />
                        </Link>

                        <Link id="link" target='_blank' to='https://www.tiktok.com/@landtrackpk?lang=en'>
                            <AiFillTikTok
                                style={{ color: 'whitesmoke', fontSize: '22px', cursor: 'pointer' }} />
                        </Link>

                    </div>
                </div>

            </section >
        </>
    );
};

export default Footer;