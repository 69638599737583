import React, { useState, useEffect } from "react";
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

import HnSLogo from '../images (6).png';
import { FaCircleCheck, FaInstagram } from "react-icons/fa6";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { CgGym, CgInstagram } from "react-icons/cg";
import { FaFacebook, FaLinkedin, FaSwimmingPool, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { MdDoorSliding } from "react-icons/md";
import { FaFire } from "react-icons/fa";
import { PiSecurityCameraFill } from "react-icons/pi";
import { GiPowerLightning } from "react-icons/gi";
import { PiThermometerHotFill } from "react-icons/pi";

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

import './hns_residence.css';

const HnSResidence = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [message, setMessage] = useState("");
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-CA'));

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = planPdf;
    //     link.download = 'Emaar ParkEdge - Payment Plan.pdf';  // Set the download filename here
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !lastName || !firstName || !contactNumber) {
            alert('Please fill in all required fields.');
            // toast('Please fill in all required fields.', {
            // });
            return;
        };

        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": "azam@landtrack.pk",
            "fingerPrint": "XBWxNl4sKfHLpl0wiIdZFJ9WMze2utIQsyAyl8RM4JtvyZYoVo3T9ihy97jybhOWZkQljg4J5tHgneJtbAvnjj9rY3RNWijjIIId",
            "userID": 1077,
            "personalMobile": contactNumber,
            "personalMobileCountryCode": "+92",
            // "rmIDs": "1077",
            "tag": "New Lead",
            "sendWelcomeLetter": false,
            "contactTypeIDs": "354",
            "leadStatus": [
                {
                    "message": message,
                    "contactusStatusID": 367,
                    "assignedTo": 1077,
                    "assignedToBy": 1077,
                    "date": todayDate
                }
            ]
        };


        try {
            const response = await fetch('https://core.fortify.biz/api/Contact/SetContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            console.log(result);
            alert('Form submitted successfully!', {
            });

            console.log(payload);
            console.log(todayDate);
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');
            setMessage("");

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };

    return (
        <>
            {/* home */}
            <section id="hns_bg">
                <div id="hns_home">
                    <img id="hns_logo" src={HnSLogo} alt="H&S Residence"
                    />
                    <h1 id="hns_main_hdng">
                        RESIDENCE
                    </h1>
                    <p id="hns_main_txt">
                        A Coastal Heaven With Japanese Architecture
                    </p>
                    <button
                        onClick={() => sectionScrollHandler('hns_contact')}
                        className="project_btn" id="hns_home_btn">
                        Booking & Details
                    </button>
                </div>
            </section>


            {/* about */}
            <section id="hns_about">
                <div id="hns_about_hdng">
                    A Coastal Heaven In DHA Phase 8
                    <b>H&S Residence</b>
                </div>
                <div id="hns_about_txt" >
                    Immerse yourself in coastal luxury at H&S Residence. Our 39-story haven offers 1-4 bedroom beachfront residences inspired by ocean waves. Enjoy a private beach, infinity pools, and modern living in a secure, green community.

                    <div id="hns_features_points">
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            1-4 Bedroom Apartments, Signature Duplexes & Penthouse
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            Twin Tower Project
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            G+41 Floors
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            6 Levels Of Car Parking
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            33% Construction Done
                        </span>
                    </div>
                </div>
            </section>


            {/* gallery */}
            <section id="hns_gallery">
                <h2 id="hns_gallery_hdng">
                    Project Gallery
                </h2>

                <MDBCarousel id="hns_carousel" showControls>
                    <MDBCarouselItem itemId={1} id="hns_carousel_img">
                        <img id="hns_carousel_img" src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1920,fit=crop/mv07eryrrxiKKWJv/15-51c998f0d3a6cc345a14ab6467404f4d-dOq4xnKNBNClvzo7.jpg'
                            className='d-flex w-90' alt='...' />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={2} id="hns_carousel_img">
                        <img id="hns_carousel_img" src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1920,fit=crop/mv07eryrrxiKKWJv/3-3f135e808aa1eeedcaaea7ffb08ea3ee-mP4Q9l0NKKf46a9q.jpg'
                            className='d-flex w-90' alt='...' />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={3} id="hns_carousel_img">
                        <img id="hns_carousel_img" src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1920,fit=crop/mv07eryrrxiKKWJv/5-c20255ffa2e48ee1789cdc959e893b47-AMq4GQyp6liJQjL9.jpg'
                            className='d-flex w-90' alt='...' />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={4} id="hns_carousel_img">
                        <img id="hns_carousel_img" src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1920,fit=crop/mv07eryrrxiKKWJv/10-4412e81a677edf26069cc9c121199e0b-AQEp1PRVa6igzkal.jpg'
                            className='d-flex w-90' alt='...' />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={5} id="hns_carousel_img">
                        <img id="hns_carousel_img" src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1920,fit=crop/mv07eryrrxiKKWJv/4-16e1f07c442beb226f01e3f84416c485-m6LJkQev4oCXaEDW.jpg' className='d-flex w-90' alt='...' />
                    </MDBCarouselItem>
                </MDBCarousel>
            </section>


            {/* amenities */}
            <section id="hns_amenities">
                <h2 id="hns_amenities_hdng">
                    Amenities
                </h2>
                <p id="hns_amenities_txt">
                    H&S Residence offers an array of extraordinary amenities that redefine the meaning of a high-quality lifestyle. Whether you prefer swimming laps or simply lounging by the pool, getting an invigorating workout in our well-equipped gym, or seeking serenity in our steam room and sauna, our resort-style offerings transport you to a world of relaxation and escape. Live the dream of a spectacular seafront life, where each day unfurls with unparalleled joy.
                </p>
                <div id="hns_amenities_div">
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/smart-7d960290f1a7d36f1117e90a8eb068f4-mP4Q9lbQzRHwR69z.jpeg" alt="" />
                        Smart Homes
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/indoorcine-4fe0ff635ee647f3d95adc88de2bc82e-1-mP4Q9lrR5nSbNo7e.jpg" alt="" />
                        Private Cinema
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/spa-031f656084afbb73a0a43e61c91f3af6-m2WQjNJQgWi72kWE.jpeg" alt="" />
                        SPA
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/yoga-52867668dada2babd4b388773508f510-mp89Pgl906sJgKOG.jpeg" alt="" />
                        GYM & Yoga Center

                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/social-fd56fa242d78d82f30f43a929df776a0-dWxbJ3obMksQGv5e.jpeg" alt="" />
                        Social Area
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/kids-60bc4aa2bd7557fe167613923c41c0dc-m6LJkQXpLetlzxob.jpeg" alt="" />
                        Kids Area
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/pool-4e3c5e0846cb9832e722818de9d68ebc-m6LJkQXnBVIyvZPQ.jpeg" alt="" />
                        <p id="hns_amenities_points_hdng">
                            Infinity Pools
                        </p>
                    </span>
                    <span>
                        <img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=122,h=107,fit=crop/mv07eryrrxiKKWJv/spaces-2af44562e529908d01544cea30b4d7f8-mjE9ygKJyNsQy1ZD.jpeg" alt="" />
                        <p id="hns_amenities_points_hdng">
                            Creative Spaces
                        </p>
                    </span>
                </div>
            </section>


            {/* contact */}
            <section id="park_edge_contact">
                <div id="park_edge_contact_content">
                    <h2 id="park_edge_contact_hdng">
                        Get In Touch With us
                    </h2>
                    <p id="park_edge_contact_txt">
                        Don't miss out on this exclusive Ocean-Front project. Fill the form and get in touch for Booking & Updates
                    </p>
                    <hr />
                    <div id="park_edge_social_div_1">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
                <div id="park_edge_contact_form">
                    <form id='form' onSubmit={handleSubmit}>
                        <MDBRow className='mb-4'>
                            <MDBCol id='field'>
                                <MDBInput
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} id='first_name_input'
                                    label='First Name'
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)} id='last_name_input'
                                    label='Last name' />
                            </MDBCol>
                        </MDBRow>
                        <MDBInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='mb-4' type='email'
                            id='email_input'
                            label='Email address'
                            placeholder='123@abc.com' />

                        <MDBInput
                            value={contactNumber}
                            onChange={e => setContactNumber(e.target.value.replace(/^(\+92|0)?/, ''))}
                            className='mb-4' type='text'
                            id='phone_input'
                            label='Contact No.'
                            placeholder='321 0000000' />

                        <MDBInput value={message} onChange={e => setMessage(e.target.value)} className='mb-4' type='text' id='msg_input' label='Your Message' />

                        <MDBBtn type='submit' className='mb-4 submit_btn' block
                            id='form_btn'
                        >Submit</MDBBtn>
                    </form>

                    <div id="park_edge_social_div_2">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HnSResidence;