import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DefaultPage from './pages/DefaultPage/DefaultPage';
import FortifyPage from './pages/FortifyPage/FortifyPage';
import DatastatePage from './pages/DatastatePage/DatastatePage';
import ProptechServicesPage from './pages/ProptechServicesPage/ProptechServicesPage';
import EventPage from './pages/EventsPage/EventsPage';

import MarketPage from './pages/MarketPlacePage/MarketPlacePage';
import Parkedge from './pages/MarketPlacePage/MarketProjects/Park_edge/Park-edge';
import HnSResidence from './pages/MarketPlacePage/MarketProjects/H&S_residence/H&S-Residence';
import GoldCrest from './pages/MarketPlacePage/MarketProjects/Gold_crest/Gold-Creast';

import ErrorPage from './pages/404Page/404Page';

const routes = (
    <Routes>
        <Route path="/" element={<DefaultPage />} />

        <Route path="/projects" element={<MarketPage />} />
        <Route path="/projects/emaar-parkedge" element={<Parkedge />} />
        <Route path="/projects/h&s-residence" element={<HnSResidence />} />
        <Route path="/projects/gold-crest" element={<GoldCrest />} />

        <Route path="/products/fortify" index element={<FortifyPage />} />
        <Route path="/products/datastate.ai" element={<DatastatePage />} />
        <Route path="/proptech/services" element={<ProptechServicesPage />} />
        <Route path="/proptech-club" element={<EventPage />} />

        <Route path="/*" element={<ErrorPage />} />
    </Routes>
);

export default routes;