import React from "react";
import './events_gallery.css';

import radissonBlu1 from './radissonblu.jpg';
import radissonBlu2 from './radissonblu (2).jpg';
import radissonBlu3 from './radissonblu (3).jpg';
import radissonBlu4 from './radissonblu (4).jpg';
import radissonBlu5 from './radissonblu (5).jpg';
import radissonBlu6 from './radissonblu (6).jpg';
import radissonBlu7 from './radissonblu (7).jpg';
import radissonBlu8 from './radissonblu (8).jpg';

import snp1 from './snp.jpeg';
import snp2 from './snp (2).jpeg';
import snp3 from './snp (3).jpeg';
import snp4 from './snp (4).jpeg';

const EventsGallery = () => {

    return (
        <>

            <section id="club_gallery_section">

                <h1 id="club_gallery_hdng">
                    Events Gallery
                </h1>

                <div id="radissonblu_event">
                    <h1 className="event_hdng">Radisson Blu Open House</h1>
                    <div className="event_images">
                        <img src={radissonBlu1} alt="" />
                        <img src={radissonBlu2} alt="" />
                        <img src={radissonBlu3} alt="" />
                        <img src={radissonBlu4} alt="" />
                        <img src={radissonBlu5} alt="" />
                        <img src={radissonBlu6} alt="" />
                        <img src={radissonBlu7} alt="" />
                        <img src={radissonBlu8} alt="" />
                    </div>
                </div>

                <div id="snp3_event">
                    <h1 className="event_hdng">SITE Nooriabad Phase III</h1>
                    <div className="event_images">
                        <img src={snp1} alt="" />
                        <img src={snp2} alt="" />
                        <img src={snp3} alt="" />
                        <img src={snp4} alt="" />
                    </div>
                </div>

            </section>

        </>
    );
};

export default EventsGallery;