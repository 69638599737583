import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './form.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

const Form = () => {

    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [message, setMessage] = useState("");
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-CA'));

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/form-visited" });
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !lastName || !firstName || !contactNumber) {
            // alert('Please fill in all required fields.');
            toast('Please fill in all required fields.', {
            });
            return;
        };

        ReactGA.event({
            category: 'Form',
            action: 'Submit',
            label: 'Form Submission'
        });

        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": "azam@landtrack.pk",
            "fingerPrint": "XBWxNl4sKfHLpl0wiIdZFJ9WMze2utIQsyAyl8RM4JtvyZYoVo3T9ihy97jybhOWZkQljg4J5tHgneJtbAvnjj9rY3RNWijjIIId",
            "userID": 1077,
            "personalMobile": contactNumber,
            "personalMobileCountryCode": "+92",
            // "rmIDs": "1077",
            "tag": "New Lead",
            "sendWelcomeLetter": false,
            "contactTypeIDs": "354",
            "leadStatus": [
                {
                    "message": message,
                    "contactusStatusID": 367,
                    "assignedTo": 1077,
                    "assignedToBy": 1077,
                    "date": todayDate
                }
            ]
        };


        try {
            const response = await fetch('https://core.fortify.biz/api/Contact/SetContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            console.log(result);
            toast('Form submitted successfully!', {
            });

            console.log(payload);
            console.log(todayDate);
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');
            setMessage("");

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };

    return (

        <>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover toastStyle={{ backgroundColor: 'white', fontFamily: 'poppins', fontSize: '15px' }} progressStyle={{ background: 'red', opacity: 'unset' }} />

            {/* <iframe src="https://landtrack.pk" style={{ width: '100%', height: '', border: 'none' }}></iframe> */}
            <section id='form_section'>
                <div id='form_txt_div'>
                    <h5 id='form_sbhdng'>
                        {t('defaultPage_contact.sub_heading')}
                    </h5>
                    <h1 id='form_hdng'>
                        {t('defaultPage_contact.main_heading')}
                    </h1>

                    <p id='form_txt'>
                        {t('defaultPage_contact.text')}
                        <br />
                        <b>
                            {t('defaultPage_contact.highlight')}
                        </b>
                    </p>
                </div>

                <div id='form_div'>

                    <form id='form' onSubmit={handleSubmit}>
                        <MDBRow className='mb-4'>
                            <MDBCol id='field'>
                                <MDBInput
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} id='first_name_input'
                                    label='First Name'
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)} id='last_name_input'
                                    label='Last name' />
                            </MDBCol>
                        </MDBRow>
                        <MDBInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='mb-4' type='email'
                            id='email_input'
                            label='Email address'
                            placeholder='123@abc.com' />

                        <MDBInput
                            value={contactNumber}
                            onChange={e => setContactNumber(e.target.value.replace(/^(\+92|0)?/, ''))}
                            className='mb-4' type='text'
                            id='phone_input'
                            label='Contact No.'
                            placeholder='321 0000000' />

                        <MDBInput value={message} onChange={e => setMessage(e.target.value)} className='mb-4' type='text' id='msg_input' label='Your Message' />

                        <MDBBtn type='submit' className='mb-4 submit_btn' block
                            id='form_btn'
                        >Submit</MDBBtn>
                    </form>

                </div>
            </section>

        </>

    );
};

export default Form;