import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import { FaPlay } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";


import './carosel.css';

import raheelImg from "./Ellipse 4.png";
import fayyazImg from "./MAX_9444.jpg";
import amanImg from "./Amanullah-Panjwani-CEO-PIBSAT-1-e1708762567816.jpg";
import arshadImg from "./Arshad-Mehmood-Awan-CEO-Homy-Properties-e1709548790944.jpg";

const Carousel = () => {

    const { t } = useTranslation();

    const carouselData = [
        {
            id: 1,
            videoLink: 'https://youtu.be/ScRM8XyIDYk?si=6n5zSM1mcDlPXGzL',
            image: raheelImg,
            name: t('defaultPage_test.test_card.raheel.name'),
            desig: t('defaultPage_test.test_card.raheel.desig'),
            content: t('defaultPage_test.test_card.raheel.text')
        },
        {
            id: 2,
            videoLink: 'https://youtu.be/XBckJ0r9X8k?si=eH6wXxhLC-HOMuSj',
            image: fayyazImg,
            name: t('defaultPage_test.test_card.fayyaz.name'),
            desig: t('defaultPage_test.test_card.fayyaz.desig'),
            content: t('defaultPage_test.test_card.fayyaz.text')
        },
        {
            id: 3,
            videoLink: 'https://youtu.be/97Hmyubl3-U?si=fS_oPPHnT495wGCa',
            image: amanImg,
            name: t('defaultPage_test.test_card.aman.name'),
            desig: t('defaultPage_test.test_card.aman.desig'),
            content: t('defaultPage_test.test_card.aman.text')
        },
        {
            id: 4,
            videoLink: 'https://youtu.be/YBOAMcrz2qM?si=5tMLLF4tE_JhrDdu',
            image: arshadImg,
            name: t('defaultPage_test.test_card.arshad.name'),
            desig: t('defaultPage_test.test_card.arshad.desig'),
            content: t('defaultPage_test.test_card.arshad.text')
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [visibleDivs, setVisibleDivs] = useState(window.innerWidth < 450 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setVisibleDivs(window.innerWidth < 450 ? 1 : 3);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % carouselData.length);
                setFade(true);
            }, 500);  // Duration of the fade out transition
        }, 3000);  // Duration between transitions

        return () => clearInterval(interval);
    }, [currentIndex, carouselData.length]);


    const next = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % carouselData.length);
            setFade(true);
        }, 500);
    };

    const prev = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : carouselData.length - 1);
            setFade(true);
        }, 500);
    };

    const getVisibleItems = () => {
        const lastIndex = currentIndex + visibleDivs;
        if (lastIndex <= carouselData.length) {
            return carouselData.slice(currentIndex, lastIndex);
        } else {
            // Wrap around the array
            return [...carouselData.slice(currentIndex, carouselData.length), ...carouselData.slice(0, lastIndex % carouselData.length)];
        }
    };

    return (

        <div id="carousel_container">
            <FaAngleLeft style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }} onClick={prev} />

            <div id="carosel"
                onDragEnd={next}
            >
                {/* {carouselData.map((item, index) => (
                    <div key={index} className={`carousel_item ${currentIndex === index ? (fade ? 'fade-in' : 'fade-out') : ''}`}>
                        <img src={item.image} alt={`Slide ${index}`} />
                        <div className="details">
                            <h5>{item.name}</h5>
                            <p>{item.desig}</p>
                            <p>{item.content}</p>
                            <Link to={item.videoLink} target='_blank'>
                                <FaPlay />
                            </Link>
                        </div>
                    </div> */}


                {
                    getVisibleItems().map((item, index) => (
                        <div key={index} id="carosel_item">

                            <div id="carosel_item_details_div">
                                <img id="carosel_item_img" src={item.image} alt="" />
                                <div id="carosel_item_txt_div">
                                    <h5 id="carosel_item_name">{item.name}</h5>
                                    <p id="carosel_item_desig">{item.desig}</p>
                                </div>
                            </div>

                            <hr id="carosel_separator" />

                            <div id="carosel_content_div">
                                <p id="carosel_content">{item.content}</p>
                            </div>

                            <button id="carosel_btn">
                                <Link id="link" target='_blank' to={item.videoLink}>
                                    {t('defaultPage_test.test_card.button')}
                                    <FaPlay style={{ marginLeft: '5px' }} />
                                </Link>
                            </button>
                        </div>
                    ))
                }
            </div>

            <FaAngleRight style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }}
                onClick={next} />
        </div>



        // <div id="carousel_container">
        //     <FaAngleLeft onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : carouselData.length - 1)} className="nav-icon" />
        //     {carouselData.map((item, index) => (
        //         <div key={index} className={`carousel_item ${currentIndex === index ? (fade ? 'fade-in' : 'fade-out') : ''}`}>
        //             <img src={item.image} alt={`Slide ${index}`} />
        //             <div className="details">
        //                 <h5>{item.name}</h5>
        //                 <p>{item.desig}</p>
        //                 <p>{item.content}</p>
        //                 <Link to={item.videoLink} target='_blank'>
        //                     <FaPlay />
        //                 </Link>
        //             </div>
        //         </div>
        //     ))}
        //     <FaAngleRight onClick={() => setCurrentIndex((currentIndex + 1) % carouselData.length)} className="nav-icon" />
        // </div>
    );
};

export default Carousel;
