import React from 'react';
import { Helmet } from 'react-helmet';
// import image from "../DefaultPage/DefaultPageSections/DefaultHome/Conference-amico.png"

const DatastatePage = () => {

    // console.clear();

    // gsap.registerPlugin(ScrollTrigger);

    // window.addEventListener('load', () => {
    //     gsap
    //         .timeline({
    //             scrolltrigger: {
    //                 trigger: '.wraper',
    //                 start: 'top top',
    //                 end: '+=150%',
    //                 pin: true,
    //                 scrub: true,
    //                 marker: true
    //             },
    //         })
    //         .to('img', {
    //             scale: 2,
    //             z: 350,
    //             transformorigin: 'center center',
    //             ease: 'power1.inout',
    //         })
    //         .to(
    //             '.section.hero',
    //             {
    //                 scale: 1.1,
    //                 transformorigin: 'center center',
    //                 ease: 'power1.inout'
    //             },
    //             '<'
    //         );
    // });

    return (
        <>

            <Helmet>
                <title>Datastate.ai - AI Data-Driven Real Estate Insights</title>
                <meta name="description" content="Discover Datastate.ai by Landtrack.pk, a revolutionary PropTech SaaS tool using AI and historical data to predict future demographics and inform real estate decisions. Ideal for both home buyers and real estate investors. Launching soon!" />
                <meta name="keywords" content="Datastate.ai, data-driven real estate, AI real estate tool, property investment insights, future property prediction, real estate SaaS, Landtrack.pk, digital real estate, AI in real estate, informed property investment" />

                {/* Open Graph */}
                <meta property="og:title" content="Datastate.ai - Data-Driven real Estate Insights" />
                <meta property="og:description" content="Informed data-driven real estate decisions, powered by AI." />
                <meta property="og:image" content="https://www.datastate.ai/images/fortify/home3.png" />
                <meta property="og:url" content="https://www.landtrack.pk/products/datastate.ai" />
                <meta property="og:type" content="website" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.landtrack.pk/products/datastate.ai",
            "name": "Landtrack.pk - Data-driven Real Estate Insights",
            "description": "Informed data-driven real estate decisions, powered by AI."
          }
          `}
                </script>
            </Helmet>

            <iframe title='Datastate.ai' src="https://datastate.ai/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
        </>

        //     <div className='wrapper'>
        //         <div className='content'>
        //             <section className='section hero'></section>
        //             <section className='section gradient-purple'></section>
        //             <section className='section gradient-blue'></section>
        //         </div>
        //         <div className='image-container'>
        //             <img src={image} alt="" />
        //         </div>
        //     </div>
    );
};

export default DatastatePage;