import React from "react";

import './event_about.css';

import about_img from './Untitled design.png';
import { FaCheckCircle } from "react-icons/fa";


const EventAbout = () => {

    return (
        <>

            <section id="event_about_section">

                <div id="event_about_nmbrs_div">
                    <h1 id="member_nmbr">
                        500+
                    </h1>
                    <p id="member_txt">Executive Members</p>
                </div>

                <div id="event_about_txt_div">
                    <h1 id="event_about_hdng">
                        About PropTech Club
                    </h1>

                    <p id="event_about_txt">
                        The premier hub for real estate professionals, tech enthusiasts, investors, and innovators. We are dedicated to revolutionizing the property landscape by bridging the gap between real estate and technology.
                    </p>
                    <h5 id="event_about_highlight">Exclusive Member Benefits</h5>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#0049AC', fontSize: '17px', marginRight: '7px' }} />
                        Connect with industry leaders and potential clients through our events.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#0049AC', fontSize: '17px', marginRight: '7px' }} />
                        Stay ahead with curated webinars, and expert-led training sessions.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#0049AC', fontSize: '17px', marginRight: '7px' }} />
                        Discover unique investment prospects within the PropTech sphere.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#0049AC', fontSize: '17px', marginRight: '7px' }} />
                        Benefit from a unified voice in industry-related policy discussions.
                    </span>
                </div>

            </section>

        </>
    );
};

export default EventAbout;