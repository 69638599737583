import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useHref } from "react-router-dom";

import './services.css';

// import Typical from 'react-typical';
import RotatingText from "../../DefaultPageComponents/RotateText/RotateText";
import { MdKeyboardArrowRight } from "react-icons/md";
import dataDash from './data dashboard.png';
import marketingDash from './marketing dashboard.png';
import fortifyDash from './fortify dash.png';
import vrImage from './al knowledge (5).png';
import LegalDash from './lawtrack dashboard.png';
import datastateLogo from './logo1.png';
import fortifyLogo from './logo1 (1).png';
import lawtrackLogo from './lawtrack-220x54.png';
import realtorsLogo from './realtorstrack.pk.png';
import techDash from './al knowledge (20).png';
import reitDash from './al knowledge (21).png';

const Services = () => {

    const { t } = useTranslation();

    const [b2bClicked, setB2bClicked] = useState(true);
    const [b2cClicked, setB2cClicked] = useState(false);

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };


    return (
        <>
            <section id="service_section">
                <h1 id="service_hdng">
                    {t('defaultPage_services.main_heading')}
                </h1>
                <p id="service_txt">
                    {
                        b2bClicked
                            ?
                            t('defaultPage_services.subtext.b2b')
                            :
                            t('defaultPage_services.subtext.b2c')
                    }
                </p>


                <div id="switch_btn_div">
                    <button id={b2bClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2bClicked(true); setB2cClicked(false); }}>
                        B2B
                    </button>
                    <button id={b2cClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2cClicked(true); setB2bClicked(false); }}>
                        B2C
                    </button>
                </div>

                {b2bClicked

                    ?

                    (<div id="services_div"
                        onDragEnd={() => { setB2cClicked(true); setB2bClicked(false); }}>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.datastate.heading')}
                                </h3>
                                <span id="powered_by_txt">powered by
                                    <Link to={'/products/datastate.ai'} target='_blank'>
                                        <img id="powered_by_img"
                                            src={datastateLogo} alt="" />
                                    </Link>
                                </span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.datastate.points.0'),
                                            t('defaultPage_services.service.datastate.points.1'),
                                            t('defaultPage_services.service.datastate.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='/products/datastate.ai'>
                                        {t('defaultPage_services.service.datastate.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>

                        {/* marketing */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={marketingDash} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.marketing.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.marketing.points.0'),
                                            t('defaultPage_services.service.marketing.points.1'),
                                            t('defaultPage_services.service.marketing.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#ef4723' }}>
                                    <Link id="link" target='_blank' to='/proptech/services'>
                                        {t('defaultPage_services.service.marketing.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* fortify */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.fortify.heading')}
                                </h3>
                                <span
                                    id="powered_by_txt">powered by
                                    <Link to={'/products/fortify'} target='_blank'>
                                        <img id="powered_by_img" src={fortifyLogo} alt="" style={{ width: '45px' }} />
                                    </Link>
                                </span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.fortify.points.0'),
                                            t('defaultPage_services.service.fortify.points.1'),
                                            t('defaultPage_services.service.fortify.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#0C9780' }}>
                                    <Link id="link" target='_blank' to='/products/fortify'>
                                        {t('defaultPage_services.service.fortify.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={fortifyDash} alt="" />
                            </div>
                        </div>

                        {/* ar/vr */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={vrImage} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.arvr.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.arvr.points.0'),
                                            t('defaultPage_services.service.arvr.points.1'),
                                            t('defaultPage_services.service.arvr.points.2')]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#ef4723' }}>
                                    <Link id="link" target='_blank' to='/proptech/services'>
                                        {t('defaultPage_services.service.arvr.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* legal */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.legal.heading')}
                                </h3>
                                <span
                                    id="powered_by_txt">powered by
                                    <Link to={'https://lawtrack.pk'} target='_blank'>
                                        <img id="powered_by_img" src={lawtrackLogo} alt="" style={{ width: '75px' }} />
                                    </Link>
                                </span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.legal.points.0'),
                                            t('defaultPage_services.service.legal.points.1'),
                                            t('defaultPage_services.service.legal.points.2')]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#1579BD' }}>
                                    <Link id="link" target='_blank' to='https://lawtrack.pk'>
                                        {t('defaultPage_services.service.legal.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>

                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={LegalDash} alt="" />
                            </div>
                        </div>

                        {/* tech zone */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>

                            <div className="service_item_img_div">
                                <img className="service_item_img" src={techDash} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.techzone.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.techzone.points.0'),
                                            t('defaultPage_services.service.techzone.points.1'),
                                            t('defaultPage_services.service.techzone.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#ef4723' }}>
                                    <Link id="link" target='_blank' to='https://stza.landtrack.pk'>
                                        {t('defaultPage_services.service.techzone.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>
                        {/* reit */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.reit.heading')}
                                </h3>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.reit.points.0'),
                                            t('defaultPage_services.service.reit.points.1'),
                                            t('defaultPage_services.service.reit.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#ef4723' }}>
                                    <Link id="link" target='_blank' to='https://reit.landtrack.pk'>
                                        {t('defaultPage_services.service.reit.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={reitDash} alt="" />
                            </div>
                        </div>

                    </div>)

                    :

                    (<div id="services_div"
                        onDragEnd={() => { setB2bClicked(true); setB2cClicked(false); }}>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.datastateb2c.heading')}
                                </h3>
                                <span id="powered_by_txt">powered by <img id="powered_by_img" src={datastateLogo} alt="" /></span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.datastateb2c.points.0'),
                                            t('defaultPage_services.service.datastateb2c.points.1'),
                                            t('defaultPage_services.service.datastateb2c.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='/products/datastate.ai'>
                                        {t('defaultPage_services.service.datastateb2c.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>
                        {/* consultation */}
                        <div className="service_item_div">
                            <div className="service_item_img_div">
                                <img className="service_item_img" src="" alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    {t('defaultPage_services.service.realtors.heading')}
                                </h3>
                                <span id="powered_by_txt">powered by <img id="powered_by_img" src={realtorsLogo} alt="" style={{ marginLeft: '0px' }} /></span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            t('defaultPage_services.service.realtors.points.0'),
                                            t('defaultPage_services.service.realtors.points.1'),
                                            t('defaultPage_services.service.realtors.points.2')
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#EF4723' }}>
                                    <Link id="link" target='_blank' to='https://www.realtorstrack.pk'>
                                        {t('defaultPage_services.service.realtors.button')}
                                        <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                                {/* <button className="service_item_btn" style={{ backgroundColor: "" }}>
                                    Explore More <MdKeyboardArrowRight />
                                </button> */}
                            </div>
                        </div>

                    </div>)
                }

            </section>
        </>
    );
};

export default Services;