import React from "react";

import marketHomeImg from './dillon-kydd-2keCPb73aQY-unsplash (1).jpg';

import './market_home.css';

const MarketHome = () => {

    const handleCall = () => {
        window.location.href = `tel:+92 323 2675312`;
    };

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <section id="market_home_section">
            <img id="market_home_bg" src={marketHomeImg} alt="" />
            <div id="market_home_text_div">
                <h1 id="market_home_main_hdng">
                    Good Day, What's Your Reason For Visiting today?
                </h1>

                <div id="market_buttons_div">
                    <button id="market_learn_btn"
                        onClick={() => sectionScrollHandler('form_section')}>
                        Contact Us
                    </button>
                    <button id="market_call_btn" onClick={handleCall}>
                        See Listings
                    </button>
                </div>
            </div>
        </section>
    );
};

export default MarketHome;
