import React from "react";

import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import bgImage from './IMG_5612 (1).png';

import "./event_home.css";

const EventHome = () => {

    return (
        <>
            <section>
                <section id="event_home_section">
                    <img id="event_home_bg" src={bgImage} alt="" />
                    <div id="event_home_txt_div">

                        <h5 id="event_home_sbhdng">
                            Creating Opportunities In PropTech With
                        </h5>
                        <h1 id="event_home_hdng">
                            PropTech Club
                        </h1>

                        <p id="event_home_txt" >
                            Your Gateway to Innovation, Education, and Collaboration in PropTech
                        </p>

                        <div id="event_home_buttons_div">
                            {/* <button id="event_home_learn_btn">
                                Learn More
                            </button> */}
                            <button id="event_home_join_btn">
                                <Link id="link" target='_blank' to='https://chat.whatsapp.com/KuZaeX9pGvVDG2Ux2ivzlp'>
                                    Join Club
                                    <MdKeyboardArrowRight />
                                </Link>
                            </button>
                        </div>

                    </div>

                </section >
            </section >
        </>
    );
};

export default EventHome;

