import React from "react";

import errorImg from './BACKGROUND_1.png';

const ErrorPage = () => {

    return (
        <>
            <section style={{ width: '100%', height: '100vh', padding: '50px' }}>

                <center>
                    <img style={{ width: '35%', paddingBottom: '40px' }} src={errorImg} alt="" />

                    <p style={{ color: '#083554', fontFamily: 'poppins', fontSize: '16px' }}>You may have accidentally mistyped the page address, or followed an expired link. Anyway, we will help you get back on track.</p>
                    <button
                        style={{ width: '150px', color: 'white', fontSize: '14px', fontFamily: 'poppins', fontWeight: '300', backgroundColor: '#083554', border: 'none', padding: '7px 15px' }}
                        onClick={() => { window.location.href = 'https://landtrack.pk' }}>Back To Home</button>
                </center>

            </section>
        </>
    );
};

export default ErrorPage;