import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdb-react-ui-kit';

import { FaCaretDown } from "react-icons/fa";

import './langSwitch.css';

const LanguageSwitch = () => {
    const { i18n } = useTranslation();

    // Function to toggle the language
    const toggleLanguage = () => {
        const newLang = i18n.language === 'ur' ? 'en' : 'ur';
        i18n.changeLanguage(newLang);
    };

    // Get current language for display
    const currentLanguage = i18n.language === 'ur' ? 'اردو' : 'English';
    // Determine the language option to display in the dropdown
    const otherLanguage = i18n.language === 'ur' ? 'English' : 'اردو';

    return (
        <>

            <MDBDropdown id='lang_btn' style={{ boxShadow: 'none', position: 'relative' }}>
                <MDBDropdownToggle id='lang_dropdown_btn' >
                    {currentLanguage}
                    <FaCaretDown style={{ marginLeft: '3px', fontSize: '13px' }} />
                </MDBDropdownToggle>
                <MDBDropdownMenu id='dropdown_menu'>
                    <MDBDropdownItem onClick={toggleLanguage} >
                        {otherLanguage}
                    </ MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </>
    );
};

export default LanguageSwitch;
