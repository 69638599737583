import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import Hotjar from './Hotjar';
import './index.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import './i18n';

import App from './App';

ReactGA.initialize('G-EXB8E1DXH0');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="597411897546-f6sq9eul23egeqa1aunnvev671jjdk4i.apps.googleusercontent.com">
        <Hotjar />
        <App />
    </GoogleOAuthProvider>
);