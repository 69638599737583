import React from "react";
import { useTranslation } from 'react-i18next';
import './affiliate.css';
import { Link } from "react-router-dom";

import affiliateImg from './Ready to Launch your Career (4).png';
import { FaAngleRight } from "react-icons/fa";


const Affiliate = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="affiliate_section">
                <div id="background_image"></div>

                <div id="affiliate_info_div">
                    <h5 id="affiliate_sbhdng">
                        {t('defaultPage_affiliate.sub_heading')}
                    </h5>
                    <h1 id="affiliate_hdng">
                        {t('defaultPage_affiliate.main_heading')}
                    </h1>

                    <p id="affiliate_txt">
                        {t('defaultPage_affiliate.text')}
                    </p>

                    <p id="affiliate_cta_txt">
                        {t('defaultPage_affiliate.highlight')}
                    </p>

                    <button id="affiliate_btn">
                        <Link id="link" target='_blank' to='https://docs.google.com/forms/d/e/1FAIpQLSeO2lv-vlGO4NulMjmm69273I3c5r-H3Spoxqe5DD68kvr9aw/viewform'>
                            {t('defaultPage_affiliate.button')}

                            <FaAngleRight />
                        </Link>
                    </button>
                </div>

                <div id="affiliate_img_div">
                    <img id="affiliate_img" src={affiliateImg} alt="" />
                </div>

            </section >
        </>
    );
};

export default Affiliate;