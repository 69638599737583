import React from "react";
import { useTranslation } from 'react-i18next';
import RotatingText from "../../DefaultPageComponents/RotateText/RotateText";
import RotatingImages from "../../DefaultPageComponents/rotateImg/RotateImg";

import './why.css';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import experienceImg from './experience.jpg';
import dataImg from './data.png';
import networkImg from './network.png';
import conventionImg from './convention.jpeg';
import awardImg from './awards.jpeg';

const Why = () => {

    const { t } = useTranslation();

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    return (
        <>
            <section id="why_section">
                <h5 id="why_sbhdng">
                    {t('defaultPage_why.sub_heading')}
                </h5>
                <h1 id="why_hdng">
                    {t('defaultPage_why.main_heading')}
                </h1>

                {
                    checkResponsive()
                        ?
                        (
                            <div id="why_info_div">

                                <MDBCard className="info_card">
                                    <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                        <div id="info_card_img" >
                                            <RotatingImages
                                                images={[
                                                    experienceImg,
                                                    dataImg,
                                                    networkImg,
                                                    conventionImg,
                                                    awardImg]}
                                                interval={3000} />
                                        </div>
                                        {/* <MDBCardImage style={{ height: '100%' }} fluid alt='...'>
                                            <RotatingImages
                                                images={[
                                                    techImg,
                                                    knowledgeImg,
                                                    facilitationImg,
                                                    dataImg,
                                                    consultImg]}
                                                interval={3000} />
                                        </MDBCardImage> */}
                                    </MDBRipple>
                                    <MDBCardBody className="info_card_txt_div">
                                        <MDBCardTitle className="info_card_hdng">
                                            <RotatingText
                                                texts={[
                                                    t('defaultPage_why.why_card.tech.heading'),
                                                    t('defaultPage_why.why_card.knowledge.heading'),
                                                    t('defaultPage_why.why_card.facilitation.heading'),
                                                    t('defaultPage_why.why_card.data.heading'),
                                                    t('defaultPage_why.why_card.consultation.heading')
                                                ]}
                                                interval={3000}
                                            />
                                        </MDBCardTitle>
                                        <MDBCardText className="info_card_txt">
                                            <RotatingText
                                                texts={[
                                                    t('defaultPage_why.why_card.tech.text'),
                                                    t('defaultPage_why.why_card.knowledge.text'),
                                                    t('defaultPage_why.why_card.facilitation.text'),
                                                    t('defaultPage_why.why_card.data.text'),
                                                    t('defaultPage_why.why_card.consultation.text')
                                                ]}
                                                interval={3000}
                                            />
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>

                            </div>
                        )
                        :
                        (<div id="why_info_div">

                            <MDBCard className="info_card">
                                <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage style={{ height: '100%' }} src={experienceImg} fluid alt='...' />
                                </MDBRipple>
                                <MDBCardBody className="info_card_txt_div">
                                    <MDBCardTitle className="info_card_hdng">
                                        {t('defaultPage_why.why_card.tech.heading')}
                                    </MDBCardTitle>
                                    <MDBCardText className="info_card_txt">
                                        {t('defaultPage_why.why_card.tech.text')}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard >

                            <MDBCard className="info_card">
                                <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage style={{ height: '100%' }} src={dataImg} fluid alt='...' />
                                </MDBRipple>
                                <MDBCardBody className="info_card_txt_div">
                                    <MDBCardTitle className="info_card_hdng">
                                        {t('defaultPage_why.why_card.knowledge.heading')}
                                    </MDBCardTitle>
                                    <MDBCardText className="info_card_txt">
                                        {t('defaultPage_why.why_card.knowledge.text')}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>

                            <MDBCard className="info_card">
                                <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage style={{ height: '100%' }} src={networkImg} fluid alt='...' />
                                </MDBRipple>
                                <MDBCardBody className="info_card_txt_div">
                                    <MDBCardTitle className="info_card_hdng">
                                        {t('defaultPage_why.why_card.facilitation.heading')}
                                    </MDBCardTitle>
                                    <MDBCardText className="info_card_txt">
                                        {t('defaultPage_why.why_card.facilitation.text')}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>

                            <MDBCard className="info_card">
                                <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage style={{ height: '100%' }} src={conventionImg} fluid alt='...' />
                                </MDBRipple>
                                <MDBCardBody className="info_card_txt_div">
                                    <MDBCardTitle className="info_card_hdng">
                                        {t('defaultPage_why.why_card.data.heading')}
                                    </MDBCardTitle>
                                    <MDBCardText className="info_card_txt">
                                        {t('defaultPage_why.why_card.data.text')}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>

                            <MDBCard className="info_card">
                                <MDBRipple id="info_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage style={{ height: '100%' }} src={awardImg} fluid alt='...' />
                                </MDBRipple>
                                <MDBCardBody className="info_card_txt_div">
                                    <MDBCardTitle className="info_card_hdng">
                                        {t('defaultPage_why.why_card.consultation.heading')}
                                    </MDBCardTitle>
                                    <MDBCardText className="info_card_txt">
                                        {t('defaultPage_why.why_card.consultation.text')}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>

                        </div >

                        )
                }



            </section >
        </>
    );
};

export default Why;