import React from 'react';

// import ServicesNavigation from './ServicesPageComponents/ServicesNavigation/ServicesNavigation';

const ProptechServicesPage = () => {

    return (
        <>
            <iframe src="https://proptech-services-page.netlify.app/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
        </>
    );
};

export default ProptechServicesPage;