import React from "react";
import { useTranslation } from 'react-i18next';

import './market_about.css';

import about_img from './diagram 1.png';
import { FaCheckCircle } from "react-icons/fa";

const MarketAbout = () => {

    const { t } = useTranslation();

    return (
        <>

            <section id="market_about_section">

                <div id="market_about_img_div">
                    <img id="market_about_img" src={about_img} alt="" />
                </div>

                <div id="market_about_txt_div">
                    <h1 id="market_about_hdng">
                        {t('defaultPage_about.main_heading')}
                    </h1>

                    <p id="market_about_txt">
                        {t('defaultPage_about.subtext')}
                        <br /> {t('defaultPage_about.highlight')}
                    </p>
                    <span className="market_points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} />
                        {t('defaultPage_about.pointers.point 1')}
                    </span>
                    <span className="market_points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} />
                        {t('defaultPage_about.pointers.point 2')}
                    </span>
                    <span className="market_points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} />
                        {t('defaultPage_about.pointers.point 3')}
                    </span>
                    <span className="market_points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} />
                        {t('defaultPage_about.pointers.point 4')}
                    </span>
                </div>

            </section>

        </>
    );
};

export default MarketAbout;
