import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';

import {
  useLocation,
  BrowserRouter as Router, // Use BrowserRouter for most use cases
} from "react-router-dom";
import routes from './routes';


const App = () => {

  return (
    <>
      <Router>
        <PageViews />
        {routes}
      </Router>
    </>
  );
};


const PageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search
    });
  }, [location]);

  return null;
};

export default App;


// #083555 dark blue
// #EF4723 Orange
// 083555 dark 1275BB light gradient
// 656565 dark grey text color
