import React, { useState, useEffect } from 'react';

import './rotateText.css';

const RotatingText = ({ texts, interval }) => {
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const timerId = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setIndex(prevIndex => (prevIndex + 1) % texts.length);
                setFade(true);
            }, 300);
        }, interval);

        return () => clearInterval(timerId);
    }, [texts.length, interval]);

    return (
        <p className={`rotating-text ${fade ? 'fade-in' : 'fade-out'}`}>
            {texts[index]}
        </p>
    );
};

export default RotatingText;
