import React from 'react';
import { Helmet } from 'react-helmet';

const FortifyPage = () => {

    return (
        <>

            <Helmet>
                <title>Fortify - Straemline Your Business Management</title>
                <meta name="description" content="Explore Fortify, the Real Estate ERP software partnered with Landtrack.pk, designed to streamline property management for real estate builders and developers. Manage operations, finance, CRM, recovery, inventory, and more with ease." />
                <meta name="keywords" content="Fortify ERP, property management software, real estate ERP, finance management, CRM for real estate, inventory management, recovery management, real estate software, Landtrack.pk, real estate crm" />

                {/* Open Graph */}
                <meta property="og:title" content="Fortify - Real Estate ERP Software" />
                <meta property="og:description" content="Straemline your real estate business with fortify" />
                <meta property="og:image" content="https://fortify.landtrack.pk/static/media/home%20image.5d0c55da5b10a32b3c17.png" />
                <meta property="og:url" content="https://www.landtrack.pk/products/fortify" />
                <meta property="og:type" content="website" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.landtrack.pk/products/fortify",
            "name": "Fortify - Straemline your business management",
            "description": "Straemline your real estate business with fortify"
          }
          `}
                </script>
            </Helmet>


            <iframe title='Fortify' src="https://fortify.landtrack.pk/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
        </>
    );
};

export default FortifyPage;