import React, { useState, useEffect } from 'react';

import './rotateImg.css'

const RotatingImages = ({ images, interval = 3000 }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
                setFade(true);
            }, 300);
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);

    return (
        <img id='rotate_img' className={`rotating-text ${fade ? 'fade-in' : 'fade-out'}`}
            src={images[currentImageIndex]} alt="Rotating Image" fluid />
    );
};

export default RotatingImages;