import React, { useEffect, useState } from 'react';
import './popup.css';
import popupImage from './proptech web banner.png'; // Replace with the path to your image

const Popup = () => {
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(false);
        }, 10000);

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);

    return (
        showPopup && (
            <div className="popup-overlay">
                <div className="popup" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdebhMZju4CTgRv_LW7LaqqF-BK1hkwljMApKXNrGxh1vi5sA/viewform", "_blank")}>
                    <img src={popupImage} alt="Popup" className="popup-image" />
                    <button onClick={() => setShowPopup(false)} className="popup_close">X</button>
                </div>
            </div>
        )
    );
};

export default Popup;