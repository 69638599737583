import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import './blogs.css';

import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import { MdKeyboardArrowRight } from "react-icons/md";

import blog1Img from './technology in real estate (13).png';
import blog2Img from './technology in real estate (12).png';
import blog3Img from './technology in real estate (10).png';


const Blogs = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="blogs_section">
                <h5 id="blog_sbhdng">
                    {t('defaultPage_blog.sub_heading')}
                </h5>
                <h1 id="blog_hdng">
                    {t('defaultPage_blog.main_heading')}
                </h1>

                <div id="blog_cards_container">

                    <MDBCard className="blogs_card" onClick={() => window.open('https://blog.landtrack.pk/2024/07/17/aman-golf-view-apartments/')}>
                        <MDBRipple
                            id="blogs_card_image_div"
                            rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage id="blog_card_img" src={blog1Img} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('defaultPage_blog.blog_card.blog1.heading')}
                            </MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                {t('defaultPage_blog.blog_card.blog1.text')}
                            </MDBCardText>
                            <MDBBtn id="blog_card_btn">
                                <Link id="link" target='_blank' to='https://blog.landtrack.pk/2024/02/27/sindhs-leap-into-digital-efficiency/'>
                                    {t('defaultPage_blog.blog_card.button')}
                                    <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} />
                                </Link>
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card" onClick={() => window.open('https://blog.landtrack.pk/2024/07/15/pakistan-proptech-partnerships/')}>
                        <MDBRipple
                            id="blogs_card_image_div"
                            rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage id="blog_card_img" src={blog2Img} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('defaultPage_blog.blog_card.blog2.heading')}
                            </MDBCardTitle>
                            <p className="blog_card_txt">
                                {t('defaultPage_blog.blog_card.blog2.text')}
                            </p>
                            <MDBBtn
                                id="blog_card_btn">
                                <Link id="link" target='_blank' to='https://blog.landtrack.pk/2024/06/21/embracing-digital-real-estate-in-pakistan/'>
                                    {t('defaultPage_blog.blog_card.button')}
                                    <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} />
                                </Link>
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card" onClick={() => window.open('https://blog.landtrack.pk/2024/06/21/embracing-digital-real-estate-in-pakistan/')}>
                        <MDBRipple
                            id="blogs_card_image_div"
                            rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage id="blog_card_img" src={blog3Img} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">
                                {t('defaultPage_blog.blog_card.blog3.heading')}
                            </MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                {t('defaultPage_blog.blog_card.blog3.text')}
                            </MDBCardText>
                            <MDBBtn id="blog_card_btn">
                                <Link id="link" target='_blank' to='https://blog.landtrack.pk/2024/02/27/empowering-women-in-real-estate/'>
                                    {t('defaultPage_blog.blog_card.button')}
                                    <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} />
                                </Link>
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                </div >

                <button
                    id="blog_btn">
                    <Link id="link" target='_blank' to='https://blog.landtrack.pk'>
                        {t('defaultPage_blog.button')}
                        <MdKeyboardArrowRight />
                    </Link>
                </button>

            </section >
        </>
    );
};

export default Blogs;