import React from "react";
import { useTranslation } from 'react-i18next';
import RotatingText from "../../DefaultPageComponents/RotateText/RotateText";
import RotatingImages from "../../DefaultPageComponents/rotateImg/RotateImg";

import './awards.css';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import nicLogo from './nic logo.png';
import founderLogo from './founder inst logo.png';
import inseadLogo from './insead logo.png';
import pashaLogo from './pasha logo.png';

const Awards = () => {

    const { t } = useTranslation();

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    return (
        <>
            <section id="awards_section">
                <h1 id="awards_hdng">
                    {t('defaultPage_awards.main_heading')}

                </h1>

                {
                    checkResponsive()
                        ?
                        (
                            <div id="awards_div">

                                {/* <MDBCard className="awards_card">
                                    <div id="awards_card_img">
                                        <RotatingImages
                                            images={[
                                                nicLogo,
                                                founderLogo,
                                                inseadLogo,
                                                pashaLogo]}
                                            interval={3000} />
                                    </div>
                                </MDBCard> */}

                                <MDBCard className="awards_card">
                                    <RotatingImages
                                        images={[
                                            nicLogo,
                                            founderLogo,
                                            inseadLogo,
                                            pashaLogo]}
                                        interval={3000} />
                                </MDBCard>

                            </div>
                        )
                        :
                        (<div id="awards_div">

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={nicLogo} fluid alt='...' />
                            </MDBCard >

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={founderLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={inseadLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={pashaLogo} fluid alt='...' />
                            </MDBCard>


                        </div >

                        )
                }



            </section >
        </>
    );
};

export default Awards;