import React, { useState, useEffect } from "react";
import './market_navigation.css'

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';

import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa";

import logo from "./landtrack logo.png";

const MarketNavigation = () => {

    const [showNavSecond, setShowNavSecond] = useState(false);
    const [openBasic, setOpenBasic] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isToggeled, setIsToggeled] = useState(false);
    // const [isMobile, setIsMobile] = useState(checkResponsive());

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    const scrollHandler = () => {
        if (window.pageYOffset > 100) {
            setIsScrolled(true);
        }
        else {
            setIsScrolled(false);
        };
    };

    const scrollCheck = () => {
        if (window.pageYOffset > 10) {
            setOpenBasic(false);
        };
    };

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('scroll', scrollCheck);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('scroll', scrollCheck);
        };
    }, []);

    useEffect(() => {
        if (openBasic) {
            setIsToggeled(true);
        }
        else {
            setIsToggeled(false);
        };
    }, [openBasic]);

    return (
        <>
            <MDBNavbar id='market_upper_navbar' expand='lg'>
                <MDBContainer fluid id='market_upper_nav_container'>

                    <MDBNavbarNav className='d-flex flex-row' id='market_upper_nav'>
                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleCall()} >
                                <FaPhoneAlt style={{ marginRight: "10px" }} />
                                +92 323 2675312
                                {/* {
                                    checkResponsive() ? "" : "+92 323 2675312"
                                } */}
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleMail()} >
                                <IoMdMail style={{ marginRight: "10px", fontSize: '18px' }} />
                                info@landtrack.pk
                                {/* {
                                    checkResponsive() ? "" : "info@landtrack.pk"
                                } */}
                            </button>
                        </MDBNavbarItem>

                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar>

            {/* <MDBNavbar id={`market_fixed_upper_navbar${isScrolled ? 'show' : ''}`} expand='lg'>
                <MDBContainer fluid id='market_upper_nav_container'>

                    <MDBNavbarNav className='d-flex flex-row' id='market_upper_nav'>
                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleCall()} >
                                <FaPhoneAlt style={{ marginRight: "10px" }} />
                                {
                                    checkResponsive() ? "" : "+92 323 2675312"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleMail()} >
                                <IoMdMail style={{ marginRight: "10px", fontSize: '18px' }} />
                                {
                                    checkResponsive() ? "" : "info@landtrack.pk"
                                }
                            </button>
                        </MDBNavbarItem>

                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar> */}

            <MDBNavbar expand='lg'
                className={`market_navbar_${isScrolled ? 'scrolled' : ''}`}
                id={`market_main_navbar${isScrolled ? 'scrolled' : ''}`}>
                <MDBContainer fluid id='market_navbar_container'>

                    <MDBNavbarBrand id='market_logo_container'
                    >
                        <img
                            id='market_logo'
                            src={logo} alt="logo"
                            onClick={() => window.location.reload()}
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setOpenBasic(!openBasic)}
                        style={{ paddingRight: '7%', color: '#083555' }}
                    >
                        {isToggeled ? <MDBIcon fas icon="times" /> : <MDBIcon className='bars_icon' icon='bars' fas />}
                    </MDBNavbarToggler>

                    <MDBCollapse navbar open={openBasic} id='market_collapse_container'>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' id='market_navbar'>

                            <MDBNavbarItem id='market_nav_item'>
                                <button
                                    id='market_nav_btn'
                                    onClick={() => sectionScrollHandler("market_home_section")} >
                                    Home
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='market_nav_item'>
                                <button
                                    id='market_nav_btn'
                                    onClick={() => sectionScrollHandler("market_about_section")} >
                                    About
                                </button>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='market_nav_item'>
                                <button
                                    id='market_nav_btn'
                                    onClick={() => sectionScrollHandler("market_projects_section")} >
                                    Projects
                                </button>
                            </MDBNavbarItem>

                            {/* <MDBNavbarItem id='market_nav_item'>
                                <button
                                    id='market_nav_btn'
                                    onClick={() => sectionScrollHandler("home_section")} >
                                    How It Works?
                                </button>
                            </MDBNavbarItem> */}

                            <MDBNavbarItem id='market_nav_item'>
                                <button
                                    id='market_nav_btn'
                                    onClick={() => sectionScrollHandler("form")} >
                                    Contact
                                </button>
                            </MDBNavbarItem>

                        </MDBNavbarNav>
                    </MDBCollapse>

                </MDBContainer>
            </MDBNavbar>
        </>
    );
};

export default MarketNavigation;
