import React from "react";

import EventHome from "./EventHome/EventHome";
import EventAbout from "./EventAbout/EventAbout";
import EventsGallery from "./EventsGallery/EventsGallery";
import Form from "../DefaultPage/DefaultPageComponents/Form/Form";
import Footer from "../DefaultPage/DefaultPageComponents/Footer/Footer";

const EventPage = () => {


    return (
        <>
            <EventHome />
            <EventAbout />
            <EventsGallery />
            <Form />
            <Footer />
        </>
    );
};

export default EventPage;