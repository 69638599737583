import React, { useState, useEffect } from "react";
import MarketNavigation from "../../MarketNavigation/MarketNavigation";

import { FaCircleCheck, FaInstagram } from "react-icons/fa6";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { CgGym, CgInstagram } from "react-icons/cg";
import { TbCoffee } from "react-icons/tb";
import { GiKidSlide } from "react-icons/gi";
import { FaFacebook, FaLinkedin, FaSwimmingPool, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

import './gold-crest.css';

const GoldCrest = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [message, setMessage] = useState("");
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-CA'));

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = planPdf;
    //     link.download = 'Emaar ParkEdge - Payment Plan.pdf';  // Set the download filename here
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !lastName || !firstName || !contactNumber) {
            alert('Please fill in all required fields.');
            // toast('Please fill in all required fields.', {
            // });
            return;
        };

        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": "azam@landtrack.pk",
            "fingerPrint": "XBWxNl4sKfHLpl0wiIdZFJ9WMze2utIQsyAyl8RM4JtvyZYoVo3T9ihy97jybhOWZkQljg4J5tHgneJtbAvnjj9rY3RNWijjIIId",
            "userID": 1077,
            "personalMobile": contactNumber,
            "personalMobileCountryCode": "+92",
            // "rmIDs": "1077",
            "tag": "New Lead",
            "sendWelcomeLetter": false,
            "contactTypeIDs": "354",
            "leadStatus": [
                {
                    "message": message,
                    "contactusStatusID": 367,
                    "assignedTo": 1077,
                    "assignedToBy": 1077,
                    "date": todayDate
                }
            ]
        };


        try {
            const response = await fetch('https://core.fortify.biz/api/Contact/SetContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            console.log(result);
            alert('Form submitted successfully!', {
            });

            console.log(payload);
            console.log(todayDate);
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');
            setMessage("");

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };

    return (
        <>
            <MarketNavigation />

            {/* home... */}
            <section id="gold_crest_bg">
                <div id="gold_crest_home">
                    <h1 id="gold_crest_main_hdng">
                        Gold Crest <br />
                        <b id="gold_crest_hdng" >Bay Sands</b>
                    </h1>
                    <p id="gold_crest_main_txt">
                        A Beach-Front Paradise Comprising 3 Sky-High Towers
                    </p>
                    <button
                        onClick={() => sectionScrollHandler('park_edge_contact')}
                        className="project_btn" id="gold_crest_home_btn">
                        Booking & Details
                    </button>
                </div>
            </section>


            {/* about... */}
            <section id="gold_crest_about">
                <div id="gold_crest_about_hdng">
                    Experience The Ultimate Coastal Lifestyle
                </div>
                <div id="gold_crest_about_txt" >
                    Imagine waking up to the sound of waves and soaking in the Arabian Sea views from your 1, 2, or 3-bedroom apartment, townhouse, or penthouse. This stunning development, opening in 2028, offers a private beach for relaxation, world-class amenities for entertainment, and a secure gated community for peace of mind. Gold Crest Bay Sands - where beachfront living meets paradise.
                    <span>Expected Completion: <b>2028</b>
                        <br />
                        <b>10%</b> Downpayment & <b>Monthly</b> Instalments
                    </span>
                </div>
            </section>


            {/* featutres & plan... */}
            <section id="gold_crest_features">
                <div id="gold_crest_features_txt_div">
                    <h2 id="gold_crest_features_hdng">
                        Key Features & Amenities
                    </h2>

                    <div id="gold_crest_features_points">
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            1, 2, & 3 Bed Apartments, Townhouses & Penthouses
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            G + 33 Floors Project
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            High Rental Yield & Capital Gain Potential
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            Private Beach & Club House
                        </span>
                    </div>
                </div>
                <div id="gold_crest_features_img_div">
                    <img id="gold_crest_features_img" src='https://static.wixstatic.com/media/ee0244_07685b45b4f24256a8c27bcebe714ccc~mv2.png/v1/fill/w_408,h_229,al_c,q_80,usm_0.66_1.00_0.01/ee0244_07685b45b4f24256a8c27bcebe714ccc~mv2.png' alt="payment plan" />
                </div>
            </section>



            {/* ameneties... */}
            <section id="gold_crest_amenities">
                <div id="gold_crest_amenities_img">
                </div>
                <div id="gold_crest_amenities_content">
                    Dive into a world of comfort and convenience. Explore our extensive range of features and amenities, designed to cater to your every need and elevate your lifestyle.

                    <div id="gold_crest_amenities_icn_div">
                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <FaSwimmingPool style={{ fontSize: '50px' }} />
                            Infinity Pools
                        </span>

                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <FaGamepad style={{ fontSize: '50px' }} />
                            Indoor Games                        </span>

                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <GiKidSlide style={{ fontSize: '50px' }} />
                            Kids Play Area
                        </span>

                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <TbCoffee style={{ fontSize: '50px' }} />
                            Caffeteria
                        </span>

                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <CgGym style={{ fontSize: '50px' }} />
                            GYM & Fitness
                        </span>

                        <span style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <HiMiniBuildingLibrary style={{ fontSize: '50px' }} />
                            Community Hall
                        </span>
                    </div>
                </div>
            </section>


            {/* contact... */}
            <section id="park_edge_contact">
                <div id="park_edge_contact_content">
                    <h2 id="park_edge_contact_hdng">
                        Get In Touch With us
                    </h2>
                    <p id="park_edge_contact_txt">
                        Don't miss out on this exclusive Ocean-Front project. Fill the form and get in touch for Booking & Updates
                    </p>
                    <hr />
                    <div id="park_edge_social_div_1">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
                <div id="park_edge_contact_form">
                    <form id='form' onSubmit={handleSubmit}>
                        <MDBRow className='mb-4'>
                            <MDBCol id='field'>
                                <MDBInput
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} id='first_name_input'
                                    label='First Name'
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)} id='last_name_input'
                                    label='Last name' />
                            </MDBCol>
                        </MDBRow>
                        <MDBInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='mb-4' type='email'
                            id='email_input'
                            label='Email address'
                            placeholder='123@abc.com' />

                        <MDBInput
                            value={contactNumber}
                            onChange={e => setContactNumber(e.target.value.replace(/^(\+92|0)?/, ''))}
                            className='mb-4' type='text'
                            id='phone_input'
                            label='Contact No.'
                            placeholder='321 0000000' />

                        <MDBInput value={message} onChange={e => setMessage(e.target.value)} className='mb-4' type='text' id='msg_input' label='Your Message' />

                        <MDBBtn type='submit' className='mb-4 submit_btn' block
                            id='form_btn'
                        >Submit</MDBBtn>
                    </form>

                    <div id="park_edge_social_div_2">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GoldCrest;